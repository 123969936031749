/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

import './src/styles/global.css';

import '@fontsource/asap';

// gatsby-browser.js
export const onClientEntry = () => {
    if (typeof window !== 'undefined') {
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:5069691,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');

        (function() {
            window.sib = {
                equeue: [],
                client_key: "speg5ke6fx48ugug5xf6ei02"
            };
            /* OPTIONAL: email for identify request*/
            // window.sib.email_id = 'example@domain.com';
            window.sendinblue = {};
            for (var j = ['track', 'identify', 'trackLink', 'page'], i = 0; i < j.length; i++) {
            (function(k) {
                window.sendinblue[k] = function() {
                    var arg = Array.prototype.slice.call(arguments);
                    (window.sib[k] || function() {
                            var t = {};
                            t[k] = arg;
                            window.sib.equeue.push(t);
                        })(arg[0], arg[1], arg[2], arg[3]);
                    };
                })(j[i]);
            }
            var n = document.createElement("script"),
                i = document.getElementsByTagName("script")[0];
            n.type = "text/javascript", n.id = "sendinblue-js", n.async = !0, n.src = "https://sibautomation.com/sa.js?key=" + window.sib.client_key, i.parentNode.insertBefore(n, i), window.sendinblue.page();
        })();
        
    }
  };
  